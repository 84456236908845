import "./App.css";
import ContactForm from "./ContactForm";
import logo from "./logo.gif";
import staticLogo from "./static-logo.svg";

function App() {
  return (
    <div className="App">
      <div
        onClick={() => (window.location.href = "https://waveonsui.com")}
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          margin: "24px 24px",
          gap: 16,
        }}
      >
        <img src={staticLogo} alt="logo" />
        <p style={{ fontSize: 20, fontWeight: "bold", color: "#FFF" }}>
          Wave Wallet
        </p>
      </div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ fontSize: 30, fontWeight: "700" }}>Get Support</p>
        <p
          style={{
            fontSize: 16,
            fontWeight: "700",
            marginTop: -20,
          }}
        >
          Wave on SUI
        </p>
        <div style={{ minWidth: 350, maxWidth: 500, width: "100%" }}>
          <ContactForm />
        </div>
      </header>
    </div>
  );
}

export default App;
